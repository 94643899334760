import { css } from '@emotion/css';
import colors from '@styles/colors';
import { smallMobile, mobileOnly, desktopUp, tabletPortraitUp, tabletLandscapeUp, tabletLandscapeDown, desktopDown, specific_1400, specific_1110 } from '@styles/breakpoints';
import { imgV2 as img } from '@styles/utils';

export default {
    container: css({
        '.section': {
            '&.hero': {
                backgroundColor: '#E3EBFF',
                justifyContent: 'flex-start',
            }
        },
        p: {
            marginBottom: 0,
        },
        '#slide-1-4': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
        },
        '.page-container': {
            maxWidth: 1440,
            width: '100%',
            margin: '0 auto',
            paddingLeft: 40,
            paddingRight: 40,
            position: 'relative',
            [mobileOnly]: {
                paddingLeft: 24,
                paddingRight: 24,
            },
            '.inner-container': {
                paddingLeft: 40,
                paddingRight: 40,
                width: '100%',
                textAlign: 'center',
                position: 'relative',
                [mobileOnly]: {
                    paddingLeft: 0,
                    paddingRight: 0,
                },
                'h3': {
                    color: colors.RED,
                    fontWeight: 800,
                    fontSize: 26,
                    lineHeight: '26px',
                    textTransform: 'uppercase',
                    marginBottom: 10,
                    [mobileOnly]: {
                        fontSize: 14,
                        lineHeight: '24px',
                        letterSpacing: '0.1em',
                        textAlign: 'center',
                    },
                    [tabletPortraitUp]: {
                        fontSize: 18,
                        lineHeight: '23px',
                        letterSpacing: '3px',
                    },
                    [tabletLandscapeUp]: {
                        fontSize: 24,
                        lineHeight: '30px',
                        letterSpacing: '0.2em',
                    },
                },
                'h2': {
                    color: colors.NAVY,
                    fontWeight: 800,
                    fontSize: 50,
                    lineHeight: '60px',
                    textTransform: 'lowercase',
                    marginBottom: 43,
                    [mobileOnly]: {
                        fontSize: 26,
                        lineHeight: '135%',
                        letterSpacing: '0.02em',
                        marginBottom: 33,
                        textAlign: 'center',
                    },
                    [tabletPortraitUp]: {
                        fontSize: 32,
                        lineHeight: '45px',
                    },
                    [tabletLandscapeUp]: {
                        fontSize: 40,
                        lineHeight: '50px',
                    },
                },
                p: {
                    fontWeight: 600,
                    fontSize: 18,
                    lineHeight: '30px',
                    maxWidth: 680,
                    width: '100%',
                    margin: '0 auto',
                    marginBottom: '45px',
                    [mobileOnly]: {
                        fontSize: 15,
                        lineHeight: '145%',
                        letterSpacing: '0.01em',
                    },
                },
                '.explore': {
                    fontWeight: 600,
                    fontSize: 13,
                    lineHeight: '16px',
                    textTransform: 'uppercase',
                    textDecoration: 'none',
                    color: colors.NAVY,
                    letterSpacing: '0.2em',
                    transition: 'all 0.3s ease-in-out 0s',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    position: 'relative',
                    overflow: 'hidden',
                    paddingBottom: 6,
                    zIndex: 10,

                    '&:before': {
                        content: `""`,
                        display: 'block',
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        width: '100%',
                        height: 2,
                        animationDuration: '0.8s',
                        animationTimingFunction: 'cubic-bezier(0.58, 0.3, 0.005, 1)',
                        animationFillMode: 'forwards',
                        transition: 'inherit',
                        background: colors.RED,
                    },

                    '&:hover:before': {
                        animationName: 'bottomFillAnim',
                    },

                    ' @-webkit-keyframes bottomFillAnim': {
                        '0%,100%': {
                            WebkitTransform: 'translateX(0)',
                            MsTransform: 'translateX(0)',
                            transform: 'translateX(0)',
                        },
                        '50%': {
                            WebkitTransform: 'translateX(100%)',
                            MsTransform: 'translateX(100%)',
                            transform: 'translateX(100%)',
                        },
                        '50.01%': {
                            WebkitTransform: 'translateX(-100%)',
                            MsTransform: 'translateX(-100%)',
                            transform: 'translateX(-100%)',
                        }
                    },

                    '@keyframes bottomFillAnim': {
                        '0%,100%': {
                            WebkitTransform: 'translateX(0)',
                            MsTransform: 'translateX(0)',
                            transform: 'translateX(0)',
                        },
                        '50%': {
                            WebkitTransform: 'translateX(100%)',
                            MsTransform: 'translateX(100%)',
                            transform: 'translateX(100%)',
                        },
                        '50.01%': {
                            WebkitTransform: 'translateX(-100%)',
                            MsTransform: 'translateX(-100%)',
                            transform: 'translateX(-100%)',
                        }
                    }
                }
            },
            '.shop-all-wrapper': {
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                marginTop: 50,

                [desktopDown]: {
                    display: 'none',
                    marginTop: 0,
                },

                '.shop-all': {
                    position: 'relative',
                    overflow: 'hidden',
                    fontWeight: 700,
                    fontSize: 15,
                    lineHeight: '16px',
                    letterSpacing: '0.2em',
                    textTransform: 'uppercase',
                    textDecoration: 'none',
                    transition: 'all 0.3s ease-in-out 0s',
                    verticalAlign: 'middle',
                    paddingBottom: '6px',
                    zIndex: 10,

                    [mobileOnly]: {
                        fontSize: 13,
                    },

                    '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        width: '100%',
                        height: '2px',
                        animationDuration: '0.8s',
                        animationTimingFunction: 'cubic-bezier(0.58, 0.3, 0.005, 1)',
                        animationFillMode: 'forwards',
                        transition: 'inherit',
                        backgroundColor: '#FC4D38',
                    },

                    '&:hover': {
                        textDecoration: 'none',

                        '&:before': {
                            animationName: 'bottomFillAnim'
                        }
                    }
                }
            }
        },
        'a.button.red.v2': {
            borderRadius: 25,
            padding: '15px 45px',
            textDecoration: 'none',
            '&:hover': {
                color: colors.RED,
                backgroundColor: colors.PINK_SHOP_BUTTON,
                borderColor: colors.PINK_SHOP_BUTTON,
            },
            '&::before, &::after': {
                content: 'none',
                dsplay: 'none',
            }
        },
        'a.button.blue.v2': {
            borderRadius: 25,
            padding: '15px 45px',
            color: colors.PINK_LIGHT,
            backgroundColor: colors.NAVY,
            borderColor: colors.NAVY,
            textDecoration: 'none',
            display: 'inline-block',
            '&:hover': {
                color: colors.PINK_LIGHT,
                backgroundColor: colors.BLUE,
                borderColor: colors.BLUE,
            }
        },
        [mobileOnly]: {
            '.page-wrapper': {
                height: '100% !important',
                '.a': {
                    height: '100% !important',
                    display: 'block !important',

                    '#slide-1': {
                        position: 'static',
                    },
                    '#slide-2, #slide-3': {
                        display: 'none'
                    }

                }
            },
        },
    }),

    homePage: css({
        // main
    }),

    hero: css({
        paddingTop: 50,
        backgroundColor: colors.PINK_EXTRA_LIGHT,
        minHeight: 450,
        position: 'relative',

        [tabletPortraitUp]: {
            paddingTop: 100,
        },

        [tabletLandscapeUp]: {
            paddingTop: 60,
        },

        [desktopUp]: {
            minHeight: 619,
        },

        'h1': {
            color: colors.NAVY,
            fontWeight: 800,
            fontSize: 50,
            lineHeight: '60px',
            textAlign: 'center',
            textTransform: 'lowercase',
            width: '100%',
            margin: '0 auto',
            [mobileOnly]: {
                fontSize: 26,
                lineHeight: '135%',
                letterSpacing: '0.02em',
            },
            [tabletPortraitUp]: {
                fontSize: 50,
                lineHeight: '60px',
            },
            'span': {
                display: 'block',
                [mobileOnly]: {
                    display: 'inline-block',
                }
            }
        },
        '.shop-now-warapper': {
            padding: '40px 0 40px 0',

            '.button': {
                zIndex: 1
            },
            [mobileOnly]: {
                padding: '10px 0 20px 0',
            },
        },
        '.animations': {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            alignContent: 'flex-end',
            alignItems: 'flex-end',
            justifyContent: 'space-evenly',

            '&.desktop': {
                marginTop: '-200px',
                paddingLeft: 20,
                paddingRight: 20,
                overflow: 'hidden',

                '.awards-logo': {
                    // 1280+
                    position: 'absolute',
                    zIndex: 1,
                    top: 60,
                    right: 180,
                    width: 155,
                    height: 155,
                    [desktopDown]: {
                        // 1110–1280
                        width: 117,
                        height: 117,
                        top: 140,
                        right: 60,
                    },
                    [specific_1110]: {
                        // 900–1110
                        width: 100,
                        height: 100,
                        top: 215,
                        right: 60,
                    },
                    [tabletLandscapeDown]: {
                        // 768–900
                        width: 101,
                        height: 101,
                        top: '60%',
                        right: 60,
                    },
                },
                '.hand-first': {
                    position: 'relative',
                    bottom: '-140px',

                    '.image-hand': {
                        position: 'relative',
                        zIndex: 1,
                        transform: 'translateY(100%)',
                        animation: '.3s ease-in-out 0.8s 1 bounceInUp forwards',
                        top: '-40px',
                    },
                    '.leaf-top': {
                        position: 'absolute',
                        zIndex: 0,
                        maxWidth: '80px !important',
                        left: 0,
                        top: '-45px',
                    },
                    '.leaf-bottom': {
                        position: 'absolute',
                        zIndex: 0,
                        maxWidth: '80px !important',
                        top: 100,
                        right: '-35px',
                    },
                    '.pears': {
                        position: 'absolute',
                        zIndex: 3,
                        maxWidth: '80px !important',
                        bottom: 160,
                        left: 0,
                    },
                    '.vB2': {
                        position: 'absolute',
                        maxWidth: '50px !important',
                        top: 0,
                    },
                },
                '.hand-middle': {
                    marginBottom: '-40px',
                    position: 'relative',

                    '.image-hand': {
                        position: 'relative',
                        zIndex: 1,
                        transform: 'translateY(100%)',
                        animation: '0.5s ease-in-out 1.2s 1 bounceInUp forwards',
                        left: '-50px',
                    },
                    '.leaf-middle': {
                        position: 'absolute',
                        zIndex: 0,
                        right: 120,
                        top: 190,
                        maxWidth: '80px !important',
                        transform: 'rotate(90deg)',
                    },
                    '.apple1': {
                        position: 'absolute',
                        top: 60,
                        left: 80,
                        maxWidth: '50px !important',
                    },
                    '.apple2': {
                        position: 'absolute',
                        bottom: 50,
                        right: 220,
                        maxWidth: '50px !important',
                    },
                    '.DHA': {
                        position: 'absolute',
                        top: 120,
                        right: '-30px',
                        maxWidth: '50px !important',
                    },
                },
                '.hand-last': {
                    marginBottom: '-130px',
                    position: 'relative',

                    '.image-hand': {
                        position: 'relative',
                        zIndex: 1,
                        transform: 'translateY(100%)',
                        animation: '0.7s ease-in-out 1.3s 1 bounceInUp forwards',
                        top: '-45px',
                    },
                    '.leaf-top': {
                        position: 'absolute',
                        zIndex: 0,
                        right: 0,
                        top: '-25px',
                        maxWidth: '80px !important',
                    },
                    '.leaf-bottom': {
                        position: 'absolute',
                        bottom: 180,
                        right: 100,
                        zIndex: 0,
                        maxWidth: '80px !important',
                    },
                    '.Fe': {
                        position: 'absolute',
                        bottom: 160,
                        maxWidth: '50px !important',
                    },
                    '.peas': {
                        position: 'absolute',
                        bottom: 160,
                        zIndex: 3,
                        maxWidth: '80px !important',
                        right: '-50px'
                    },
                },

                [desktopUp]: {
                    marginTop: '-280px',
                    '.hand-first': {
                        '.image-hand, .leaf-top, .leaf-bottom, .pears, .vB2': {
                            maxWidth: '100% !important',
                        },
                        '.leaf-top': {
                            top: '-65px',
                        },
                        '.leaf-bottom': {
                            right: '-75px',
                        },
                        '.vB2': {
                            top: 270,
                        }
                    },
                    '.hand-middle': {
                        marginBottom: '-110px',
                        '.image-hand, .leaf-middle, .DHA, .apple1, .apple2': {
                            maxWidth: '100% !important',
                        },
                        '.leaf-bottom': {
                            top: '90px',
                        },
                        '.image-hand': {
                            top: '-34px',
                        },
                        '.apple1': {
                            top: 70,
                            left: 130,
                        },
                        '.apple2': {
                            bottom: 100,
                        },
                        '.DHA': {
                            top: 80,
                            right: '-20px',
                        },
                    },
                    '.hand-last': {
                        '.image-hand, .leaf-top, .leaf-bottom, .Fe, .peas': {
                            maxWidth: '100% !important',
                        },
                        '.leaf-top': {
                            top: '-65px',
                        },
                        '.leaf-bottom': {
                            right: 120,
                        },
                    },
                },
                [specific_1400]: {
                    '.hand-middle': {
                        '.image-hand': {
                            top: '-50px',
                        }
                    },
                },
            },
        },
        '.separator': {
            maxWidth: '100%',
            width: '100%',
            zIndex: 2,
            position: 'absolute',
            marginTop: '-100px',
            bottom: '-8px',

            [mobileOnly]: {
                bottom: '-1px',
            }
        },

        '.mobile': {
            position: 'relative',
            marginTop: 40,

            '@keyframes scaleIn': {
                '0%': {
                    opacity: 0,
                    transform: 'scale(0)',
                },
                '100%': {
                    opacity: 1,
                    transform: 'scale(1)',

                }
            },

            '.animations': {
                height: 245,
                '.awards-logo': {
                    // 600–768
                    position: 'absolute',
                    zIndex: 1,
                    bottom: 200,
                    right: 30,
                    height: 122,
                    width: 122,
                    [mobileOnly]: {
                        // < 600
                        height: 89,
                        width: 89,  
                    }
                },
                '.hand-first': {
                    justifySelf: 'left',
                    position: 'absolute',
                    left: 0,
                    transform: 'translateY(100%)',
                    animation: '.3s ease-in-out 0.8s 1 bounceInUp forwards',

                    '.image-hand': {
                        position: 'relative',
                        zIndex: 1,
                        maxWidth: '160px !important',
                    },
                    '.leaf-top': {
                        position: 'absolute',
                        zIndex: 0,
                        left: 25,
                        top: '-25px',
                        width: 45,
                        height: 42,
                    },
                    '.leaf-bottom': {
                        position: 'absolute',
                        bottom: 80,
                        right: 0,
                        zIndex: 0,
                        width: 65,
                        height: 52,
                    }
                },
                '.hand-middle': {
                    position: 'absolute',
                    transform: 'translateY(100%)',
                    animation: '0.5s ease-in-out 1.2s 1 bounceInUp forwards',
                    top: '-40px',

                    '.image-hand': {
                        position: 'relative',
                        zIndex: 1,
                        maxWidth: '60px !important',
                    },
                    '.leaf-middle': {
                        position: 'absolute',
                        zIndex: 0,
                        left: '-30px',
                        top: '-30px',
                        width: 58,
                        height: 55,
                    },
                },
                '.hand-last': {
                    position: 'absolute',
                    right: 0,
                    transform: 'translateY(100%)',
                    animation: '0.7s ease-in-out 1.3s 1 bounceInUp forwards',

                    '.image-hand': {
                        position: 'relative',
                        zIndex: 1,
                        maxWidth: '200px !important',
                    },
                    '.leaf-top': {
                        position: 'absolute',
                        right: 10,
                        top: '-15px',
                        width: 41,
                        height: 63,
                    },
                    '.leaf-bottom': {
                        position: 'absolute',
                        bottom: 110,
                        left: 60,
                        zIndex: 0,
                        width: 54,
                        height: 40,
                    }
                },
            },

            '.fade-in': {
                position: 'absolute',
                opacity: 0,
                animation: '1.5s ease 0s 1 fadeIn forwards',

                '&.red-apple': {
                    bottom: 255,
                    left: '55%',
                    width: '21px',
                    height: '26px',
                },

                '&.apple': {
                    bottom: 205,
                    left: '57%',
                    width: 21,
                    height: 26,
                },

                '&.pears, &.peas': {
                    bottom: '-15px',
                    left: 0,
                    width: 45,
                    height: 47,
                    zIndex: 3,
                },

                '&.peas': {
                    left: 'auto',
                    right: 0,
                    height: 39
                }
            },

            '.fade-in-up': {
                position: 'absolute',
                animation: '1s ease .8s 1 fadeInUp forwards',
                width: 36,
                height: 36,
                opacity: 0,

                '&.dha': {
                    bottom: 70,
                    right: 140,
                },

                '&.fe': {
                    bottom: 20,
                    right: 110
                },

                '&.vb2': {
                    bottom: 205,
                    left: 90,
                }
            },
        },

        '.scale-up': {
            animationName: 'scaleIn',
            animationDuration: '0.8s',
            animationTimingFunction: 'ease',
            animationDelay: '1.4s',
            animationFillMode: 'forwards',
            opacity: 0,

            '&-delay': {
                '&-2': {
                    animationDelay: '2s',
                },

                '&-2-5': {
                    animationDelay: '2.5s',
                },
            }
        },
        '@keyframes bounceInUp': {
            '0%': {
                opacity: 0,
                transform: 'translateY(100%)',
            },
            '60%': {
                transform: 'translateY(10px)',
            },
            '80%': {
                transform: 'translateY(50px)',
            },
            '100%': {
                opacity: 1,
                transform: 'translateY(40px)',
            }
        },
    }),

    growing: css({
        backgroundColor: colors.BLUE_HOMEPAGE_EXTRA_LIGHT,
        position: 'relative',
        overflowX: 'hidden',
        [mobileOnly]: {
            paddingTop: 20,
            paddingBottom: 50,
        },
        [tabletPortraitUp]: {
            paddingTop: 40,
            paddingBottom: 50,
        },
        [tabletLandscapeUp]: {
            paddingTop: 40,
            paddingBottom: 50,
            minHeight: 1000,
        },

        p: {
            fontSize: 18,
        },

        h2: {
            marginBottom: 30,

            [mobileOnly]: {
                maxWidth: 270,
                margin: 'auto',
            }
        },
        '.brain-product-container-cta-desktop': {
            paddingTop: '16px !important',
            paddingBottom: '16px !important',
            fontWeight: '800 !important',
            fontSize: '14px !important',
        },
        '.subheader': {
            textAlign: 'center',
            fontWeight: 700,
            fontSize: '17px',
            lineHeight: '145%',
            letterSpacing: '0.02em',

            [mobileOnly]: {
                fontSize: '15px',
            }
        },

        '.carousel-ornament-left': {
            position: 'absolute',
            top: 300,
            width: 150,
            right: 0,
            transform: 'scaleX(-1)',
            display: 'none',
            [tabletLandscapeUp]: {
                display: 'block',
            }
        },

        '.brain-section': {
            display: 'flex',
            alignItems: 'stretch',
            marginTop: 50,

            '& > *': {
                padding: '70px 50px',
                minHeight: 620,
                height: '100%',
                width: '100%',

                [mobileOnly]: {
                    padding: '30px',
                    minHeight: 'auto',
                }
            },

            [tabletPortraitUp]: {
                backgroundColor: 'white',
                borderRadius: 50,
            },

            [mobileOnly]: {
                flexDirection: 'column',
                alignItems: 'center',
            },

            '.brain-section-description': {
                position: 'relative',

                '.brain-section-description-title': {
                    textTransform: 'uppercase',
                    fontSize: '16px !important',
                    letterSpacing: '.09em'
                },

                [tabletPortraitUp]: {
                    display: 'none'
                },

                [mobileOnly]: {
                    display: 'block',
                    height: '0 !important',
                    marginTop: -20,

                    '.brain-section-description-title': {
                        fontWeight: 800,
                        fontSize: 12,
                        textAlign: 'center',
                        letterSpacing: '0.2em',
                    },

                    '&::after': {
                        content: `""`,
                        position: 'absolute',
                        height: 11,
                        width: 10,
                        margin: '0 10px',
                        right: 10,
                        bottom: 2,
                        borderRight: '3px solid white',
                        borderBottom: '3px solid white',
                        transform: 'rotate(45deg)',
                        zIndex: 2,

                        [mobileOnly]: {
                            bottom: -32
                        },

                        [tabletLandscapeUp]: {
                            bottom: 5
                        },
                    }
                },


                '.select__control': {
                    position: 'relative',
                    borderRadius: 50,
                    backgroundColor: colors.PURPLE,
                    color: colors.WHITE,
                    height: 44,
                    appearance: 'none',
                    textAlign: 'center',

                    '.select__value-container': {
                        marginTop: '-10px',
                    },
                    '.select__placeholder': {
                        color: colors.WHITE
                    },
                    '.select__indicators': {
                        display: 'none'
                    },
                    '.select__single-value': {
                        color: colors.WHITE,
                    },
                    '&.select': {
                        position: 'relative',

                        '&:after': {
                            display: 'block',
                            borderTop: '2px solid #3856A7',
                            borderRight: '2px solid #3856A7',
                            WebkitTransform: 'rotate(135deg)',
                            MozTransform: 'rotate(135deg)',
                            MsTransform: 'rotate(135deg)',
                            OTransform: 'rotate(135deg)',
                            transform: 'rotate(135deg)',
                            height: 10,
                            width: 10,
                            content: '" "',
                            position: 'absolute',
                            top: '50%',
                            right: 20,
                            marginTop: '-6px',
                            zIndex: 1,
                        },
                    },
                },
            },
            '.brain-product-container': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '40px',
                backgroundColor: colors.PINK_EXTRA_LIGHT,
                borderBottomRightRadius: 50,
                borderTopRightRadius: 50,

                'h2': {
                    fontSize: 32
                },

                [tabletPortraitUp]: {
                    '.brain-product-container-cta-desktop': {
                        display: 'block'
                    },
                    '.brain-product-container-cta-tablet': {
                        display: 'none'
                    },
                },

                [mobileOnly]: {
                    '.brain-product-container-cta-desktop': {
                        display: 'none'
                    },
                    '.brain-product-container-cta-tablet': {
                        display: 'block'
                    },
                },

                '.brain-product-container-cta-tablet': {
                    [mobileOnly]: {
                        width: '100%'
                    }
                }
            },
            [mobileOnly]: {
                flexDirection: 'column',

                '.brain-wrapper, .brain-product-container': {
                    height: 'unset'
                },

                '.brain-wrapper': {
                    borderTopLeftRadius: 50,
                    borderTopRightRadius: 50,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,

                    '.brain-wrapper-title': {
                        display: 'none'
                    }
                },
                '.brain-product-container': {
                    borderBottomLeftRadius: 50,
                    borderBottomRightRadius: 50,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: '0 !important',
                    gap: '20px !important',

                    '.brain-product-container-description': {
                        display: 'none'
                    }
                }
            },
        },
        '.brain-wrapper': {
            width: '100%',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderTopLeftRadius: 50,
            borderBottomLeftRadius: 50,
            backgroundColor: colors.WHITE,

            [mobileOnly]: {
                flexDirection: 'column',
                order: 2,
            },
            [tabletPortraitUp]: {
                flexDirection: 'column',
                order: 2,
            },
            [tabletLandscapeUp]: {
                order: 1,
            },
            '.brain-stage-wrapper': {
                width: '100%',
                maxWidth: 245,

                'img': {
                    display: 'inline-block !important'
                },

                [tabletLandscapeUp]: {
                    minHeight: 290,
                },
            },
            'picture': {
                width: 225,

                [tabletLandscapeUp]: {
                    minHeight: 245,
                },
            },
            'img': {
                maxWidth: '100%',
                height: 'auto',
                display: 'block',
            },
            'a': {
                position: 'absolute',
                width: 80,
                color: colors.NAVY,
                fontSize: 14,
                textTransform: 'uppercase',
                whiteSpace: 'pre',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                textDecoration: 'none',
                marginBottom: 80,
                'span': {
                    marginBottom: 6,
                }
            },
            '.brain-wrapper-title': {
                textTransform: 'uppercase',
                fontWeight: '800 !important',
                letterSpacing: '.09em'
            },
            '.brain-stage-title': {
                position: 'relative',
                fontSize: '14px !important',

                '&::before': {
                    content: `""`,
                    position: 'absolute',
                    width: 10,
                    height: 150,
                    right: -16,
                    bottom: 10,
                    borderWidth: '1px 1px 1px 0px',
                    borderStyle: 'solid',
                    borderColor: colors.RED,
                    [mobileOnly]: {
                        display: 'none'
                    }
                },
                '&::after': {
                    content: `""`,
                    position: 'absolute',
                    width: 7,
                    height: 7,
                    right: -6,
                    bottom: 7,
                    borderWidth: '1px 1px 1px 0px',
                    borderStyle: 'solid',
                    borderRadius: '50%',
                    backgroundColor: colors.RED,
                    [mobileOnly]: {
                        display: 'none'
                    }
                },
            },

            '.brain-stage-description': {
                display: 'none',

                [mobileOnly]: {
                    display: 'block'
                }
            },
            '.brain-stage-more': {
                display: 'none',
                cursor: 'pointer',

                [mobileOnly]: {
                    display: 'inline-block',
                    color: '#15206B',
                    textDecoration: 'underline',
                    fontSize: 15
                }
            }
        },
        '.brain-stage-toggle': {
            backgroundColor: `${colors.PURPLE} !important`,

            '& *': {
                color: `${colors.WHITE} !important`
            }
        },
        '.brain-ruller': {
            position: 'relative',
            width: '100%',

            [mobileOnly]: {
                display: 'none'
            },

            '&:before, &:after': {
                content: `""`,
                position: 'absolute',
                top: -20,
                left: 6,
                width: 50,
                height: 150,
                background: 'linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%)',
                zIndex: 1,
                [tabletLandscapeUp]: {
                    display: 'block',
                },
            },

            '&:after': {
                left: 'unset',
                right: 0,
                background: 'linear-gradient(270deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 70%)',
            },

            '.brain-ruller-arrow-circle': {
                width: 40,
                height: 40,
                borderRadius: 20,
                position: 'absolute',
                cursor: 'pointer',
                zIndex: 2,

                '&.left': {
                    left: -40,
                    top: 48,
                },

                '&.right': {
                    right: -40,
                    top: 48,
                },

                '.brain-ruller-arrow': {
                    height: 12,
                    width: 12,
                    margin: '0 10px',
                    display: 'inline-block',
                    position: 'relative',

                    '&.left': {
                        borderLeft: '5px solid red',
                        borderBottom: '5px solid red',
                        transform: 'rotate(45deg)',
                        top: 4,
                        left: 2,
                    },

                    '&.right': {
                        borderRight: '5px solid red',
                        borderTop: '5px solid red',
                        transform: 'rotate(45deg)',
                        top: 4,
                        right: 2,
                    },
                },

                '&:hover': {
                    background: 'rgba(200, 200, 200, 0.2)'
                }
            },
        },
        '.brain-stages-control': {
            display: 'flex',
            alignItems: 'center',
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            userSelect: 'none',
            touchAction: 'manipulation !important',
            position: 'relative',
            overflow: 'hidden',
            maxWidth: 530,
            minWidth: 100,
            height: 135,
            marginLeft: 6,

            '&::-webkit-scrollbar': {
                display: 'none'
            }
            /*'&::-webkit-scrollbar': {
                display: 'none',
            },
            [mobileOnly]: {
                height: 'auto',
                width: '100vw',
            },
            [tabletPortraitUp]: {
                height: 'auto',
                width: '100vw',
            },
            [tabletLandscapeUp]: {
                height: 680,
                width: 'auto',
            }*/

        },
        '.brain-stages-wrapper': {
            display: 'flex',
            position: 'absolute',
            color: colors.BLACK,
            gap: 10,

            [mobileOnly]: {
                flexDirection: 'row',
                gap: 15,
                alignItems: 'center',
            },
            [tabletPortraitUp]: {
                flexDirection: 'row',
                gap: 5,
                alignItems: 'center',
            },
            /*[tabletLandscapeUp]: {
                flexDirection: 'column',
                gap: 30,
            },*/
            '.brain-stages': {
                width: 70,
                height: 70,
                borderRadius: '50%',
                backgroundColor: colors.BLUE_GRAY,
                boxShadow: 'inset 0 -13px 20px 0 #9D9D9D',
                transition: '.4s',

                '&.adjacent': {
                    scale: '1',
                    '&:hover': {
                        // scale: '1.2',
                        // margin: '0 5px',
                        // transition: '.4s',
                    }
                },

                '&.siblings': {
                    scale: '.9',
                    '&:hover': {
                        // scale: '1',
                        // margin: '0 5px',
                        // transition: '.4s',
                    }
                },

                '&.rest': {
                    scale: '.8',
                    margin: '0 -5px',
                    '&:hover': {
                        // scale: '.9',
                        // transition: '.4s',
                    }
                },

                '&:focus': {
                    outline: 'none',
                },

                'div': {
                    width: 'inherit',
                }
            },
            '.brain-stages.active': {
                '&': {
                    scale: '1.3',
                    margin: '0 13px',
                    backgroundColor: colors.PURPLE,
                    color: colors.WHITE,
                    boxShadow: 'inset 0 -12px 20px 0 #565656, 0 0 20px 0 #FC4D38',
                    animation: 'glowing 1s linear infinite'
                },

                '@keyframes glowing': {
                    '0%': {
                        boxShadow: 'inset 0 -12px 20px 0 #565656, 0 0 0 0 #FC4D38',
                    },
                    '50%': {
                        boxShadow: 'inset 0 -12px 20px 0 #565656, 0 0 20px 0 #FC4D38',
                    },
                    '100%': {
                        boxShadow: 'inset 0 -12px 20px 0 #565656, 0 0 0 0 #FC4D38',
                    }
                }
            },
            '.brain-months-wrapper': {
                position: 'relative',

                '.brain-months-number': {
                    position: 'absolute',
                    top: 12
                },
                '.brain-months-placeholder': {
                    fontSize: '14px !important',
                    position: 'absolute',
                    top: 30,
                    left: 11,
                    right: 11
                }
            }
        },
        '.brain-product-info': {
            width: '100%',
            textAlign: 'left',
            [mobileOnly]: {
                order: 3,
            },
            [tabletPortraitUp]: {
                flexDirection: 'column',
                order: 3,
            },
            [tabletLandscapeUp]: {
                order: 3,
            },
            '.brain-product-section': {
                fontWeight: 800,
                textTransform: 'uppercase',
                color: colors.RED,
                marginBottom: 10,
                [mobileOnly]: {
                    fontSize: 12,
                    lineHeight: '16px',
                    letterSpacing: '0.2em',
                    textAlign: 'center',
                },
                [tabletPortraitUp]: {
                    order: 3,
                    fontSize: 20,
                    lineHeight: '30px',
                    letterSpacing: 3,
                    textAlign: 'center',
                },
                [tabletLandscapeUp]: {
                    order: 3,
                    fontSize: 20,
                    lineHeight: '30px',
                    letterSpacing: 3,
                    textAlign: 'left',
                },
            },
            '.brain-heading': {
                fontWeight: 800,
                fontSize: 26,
                lineHeight: '30px',
                textTransform: 'lowercase',
                color: colors.NAVY,
                marginBottom: 20,
                [mobileOnly]: {
                    textAlign: 'center',
                },
                [tabletPortraitUp]: {
                    textAlign: 'center',
                },
                [tabletLandscapeUp]: {
                    textAlign: 'left',
                },
            },
            '.brain-description': {
                fontWeight: 700,
                textTransform: 'lowercase',
                color: colors.NAVY,
                fontSize: 15,
                lineHeight: '22px',
                [mobileOnly]: {
                    textAlign: 'center',
                    marginBottom: 50,
                },
                [tabletPortraitUp]: {
                    textAlign: 'left',
                    marginBottom: 30,
                },
                [tabletLandscapeUp]: {
                    textAlign: 'left',
                    marginBottom: 30,
                },
            },
            '.brain-action': {
                display: 'flex',
                alignItems: 'center',
                color: colors.NAVY,
                fontWeight: 800,
                fontSize: 16,
                lineHeight: '28px',
                letterSpacing: '3px',
                textTransform: 'uppercase',
                marginBottom: 30,
                [mobileOnly]: {
                    justifyContent: 'center',
                    marginBottom: 50,
                },
                [tabletPortraitUp]: {
                    justifyContent: 'center',
                },
                [tabletLandscapeUp]: {
                    justifyContent: 'flex-start',
                },
                'a': {
                    position: 'relative',
                    '&:after': {
                        content: `""`,
                        position: 'absolute',
                        top: -15,
                        left: -1,
                        width: 18,
                        height: 24,
                        backgroundImage: `url(${img('homepage3/play-audio-leaf.png')})`,
                        backgroundSize: '100%',
                        display: 'none',
                        [tabletLandscapeUp]: {
                            display: 'block',
                        },
                    }
                },
                '.play-audio': {
                    width: 40,
                    height: 40,
                    display: 'block',
                    marginRight: 20,
                }
            },
            '.brain-products': {
                backgroundColor: colors.WHITE,
                display: 'flex',
                alignContent: 'center',
                alignItems: 'stretch',
                borderRadius: 20,
                height: '130',
                textDecoration: 'none',

                [mobileOnly]: {
                    padding: '30px 20px',
                    backgroundColor: 'unset'
                },

                '.image-wrapper': {
                    position: 'relative',
                    backgroundColor: colors.BLUE_EXTRA_LIGHT,
                    maxWidth: 100,
                    minHeight: 100,
                    width: '100%',
                    minWidth: 100,
                    textAlign: 'center',
                    padding: '10px 0',
                    borderTopLeftRadius: 20,
                    borderBottomLeftRadius: 20,

                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignContent: 'center',

                    [mobileOnly]: {
                        borderRadius: '20px',
                        backgroundColor: 'unset',
                        padding: '0 !important',
                    },

                    [desktopUp]: {
                        verticalAlign: 'middle',

                        'picture': {
                            verticalAlign: 'middle',
                        },

                        '&::after, .img': {
                            content: '""',
                            display: 'inline-block',
                            height: '100%',
                            verticalAlign: 'middle',
                        },
                    },
                },
                'img, picture': {
                    maxWidth: 48,
                    display: 'inline-block',

                    [smallMobile]: {
                        maxWidth: 70
                    },
                },
                '&.pouchpacks, &.bonebrothpouchpacks, &.smoothiepacks, &.barpacks': {
                    '.image-wrapper': {
                        maxWidth: 150,
                        minWidth: 150,
                    },
                    'img, picture': {
                        maxWidth: 150,
                        [smallMobile]: {
                            maxWidth: 195
                        },
                    },
                },
                '&.bars': {
                    'img, picture': {
                        maxWidth: 100,

                        [smallMobile]: {
                            maxWidth: 130
                        },
                    },
                },
                '.brain-product-info': {
                    width: 'auto',
                    marginLeft: '40px',
                    flexGrow: '1',
                    paddingRight: 16,
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'nowrap',
                    justifyContent: 'center',
                    paddingTop: 10,
                    paddingBottom: 10,

                    [mobileOnly]: {
                        paddingRight: 0
                    },

                    '.recommended': {
                        fontWeight: 800,
                        fontSize: 16,
                        lineHeight: '16px',
                        letterSpacing: 3,
                        textTransform: 'uppercase',
                        textDecoration: 'none !important',
                        color: colors.RED,
                        marginBottom: 3,
                        [mobileOnly]: {
                            marginBottom: 5,
                            fontSize: '15px !important'
                        },
                    },
                    '.brain-product-title': {
                        fontWeight: 800,
                        fontSize: 24,
                        lineHeight: '30px',
                        textTransform: 'lowercase',
                        textDecoration: 'none !important',
                        color: colors.NAVY,
                        marginBottom: 30,
                        [mobileOnly]: {
                            lineHeight: '25px',
                            marginBottom: 20,
                            fontSize: '20px !important'
                        },
                    }
                },
                'a.button.v2': {
                    borderRadius: 25,
                    padding: '14px 35px',

                    [mobileOnly]: {
                        padding: '7px 15px',

                    }
                }
            }
        },

    }),

    about: css({
        overflow: 'hidden',
        backgroundColor: colors.PINK_LIGHT,

        [desktopUp]: {
            height: "100%",
        },

        '.play-overlay': {
            position: 'absolute',
            top: 0,
            left: 0,
        },

        '.page-container': {
            padding: '0px',
            maxWidth: '100%',

            [desktopUp]: {
                height: "100%",
            },

            '.inner-container': {
                padding: '0px',

                [desktopUp]: {
                    height: "100%",
                }
            },
        },

        '.overlay': {
            height: '100%',
            width: '100%',
            top: 0,
            left: 0,
            position: 'absolute',
            opacity: 1,
            transition: 'all 0.5s ease-in',
            backgroundSize: 'cover',

            '&.active': {
                transition: 'all 0.5s ease-in',
                boxShadow: 'inset 0 0 0 2000px rgb(21 32 107 / 69%)',
                filter: 'blur(10px)',
            }
        },

        '.select-video': {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            justifyContent: 'space-evenly',
            alignItems: 'stretch',
            alignContent: 'stretch',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',

            span: {
                width: '100%',
                height: '100%',
            }
        },

        '.homepage-video-wrapper': {
            position: 'relative',
            height: 'calc(100vh - 48px)',

            [desktopUp]: {
                picture: {
                    height: '100%',

                    img: {
                        height: '100%',
                    },
                },
            },
            [mobileOnly]: {
                height: 'auto !important',
            },

            video: {
                width: '100%',
            },

            '.video-placeholder': {
                width: '100%',
            },

            '.video-wrapper-image': {
                position: 'absolute',
                bottom: -38,
                left: '50%',
                transform: 'translateX(-50%)',
                [tabletPortraitUp]: {
                    maxWidth: 107,
                    width: '100%',
                },
                [tabletLandscapeUp]: {
                    maxWidth: 'inherit',
                    width: 'auto',
                },

            },
            '.video-play': {
                width: 120,
                height: 120,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                cursor: 'pointer',
            },
            'p.pareinting-is, p.cerebelly-is': {
                maxWidth: 183,
                width: '100%',
                fontSize: 34,
                lineHeight: '40px',
                fontWeight: 'bold',
                textAlign: 'right',
                color: colors.PINK_LIGHT,
                position: 'absolute',
                left: 30,
                top: '50%',
                transform: 'translateY(-50%)',
                textTransform: 'lowercase',
                display: 'none',
                [tabletLandscapeUp]: {
                    display: 'block',
                }
            },
            'p.cerebelly-is': {
                maxWidth: 173,
                width: '100%',
                color: colors.NAVY,
                position: 'absolute',
                textAlign: 'left',
                left: 'auto',
                right: 30,
            },
        },
        '.homepage-articles': {
            position: 'absolute',
            top: '20%',
            left: 0,
            right: 0,
            maxWidth: 1200,
            margin: 'auto',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            alignContent: 'stretch',
            justifyContent: 'center',
            alignItems: 'stretch',
            gap: 40,
            zIndex: 1,

            '.leaf-top': {
                position: 'absolute',
                left: '-70px',
                top: '-90px',
                dispaly: 'none',

                [tabletLandscapeUp]: {
                    dispaly: 'block',
                },
            },
            '.leaf-bottom': {
                position: 'absolute',
                right: '-70px',
                bottom: '-120px',
                dispaly: 'none',

                [tabletLandscapeUp]: {
                    dispaly: 'block',
                },
            },
            '.article': {
                maxWidth: 400,
                width: '100%',
                backgroundColor: '#ffd9e0',
                borderRadius: 20,
                padding: '50px 20px',
                display: 'flex',
                flexWrap: 'nowrap',
                flexDirection: 'column',
                justifyContent: 'space-between',

                '&.hidden': {
                    opacity: 0,
                    transition: 'all 0.5s ease-in',
                },

                '&.show': {
                    opacity: 1,
                    transition: 'all 0.5s ease-in',
                },

                '.heading': {
                    fontWeight: 800,
                    textTransform: 'uppercase',
                    color: colors.RED,
                    textAlign: 'center',
                    [tabletPortraitUp]: {
                        order: 3,
                        fontSize: 20,
                        lineHeight: '30px',
                        letterSpacing: 3,
                        marginBottom: 20,
                    },
                },
                '.body': {
                    color: colors.NAVY,
                    textAlign: 'center',
                    marginBottom: 30,
                    fontWeight: 600,
                    [tabletPortraitUp]: {
                        order: 3,
                        fontSize: 18,
                        lineHeight: '30px',
                    },
                },
            },
            '.video-bottom-image': {
                display: 'none'
            }
        },
        [mobileOnly]: {
            '.leaf-top, .leaf-bottom': {
                display: 'none'
            },
            '.video-bottom-image': {
                display: 'block',
                maxWidth: '100%',
                width: 100,
                position: 'absolute',
                left: '43%',
                bottom: '-25px',
            },
            '.video-placeholder': {
                borderRadius: 20,
            },
            '.slide-arrows': {
                position: 'absolute',
                zIndex: 2,
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                alignContent: 'center',
                bottom: 275,
                padding: 25,
            },
            '.overlay ': {
                display: 'none !important'
            },
            '.homepage-video-wrapper': {
                marginBottom: 0,
                padding: '60px 20px 0px 20px',
                height: '100%',

                '.video-wrapper-image': {
                    maxWidth: 107,
                    width: '100%',
                },
                '.video-play': {
                    width: 100,
                    height: 100,
                    position: 'absolute',
                    top: '57%',
                }
            },
            '.homepage-articles': {
                gap: 30,
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
                justifyContent: 'flex-start',
                overflow: 'hidden',
                minHeight: 400,

                '.article': {
                    '.heading': {
                        fontSize: 22,
                        lineHeight: '16px',
                        letterSpacing: '0.2em',
                        marginBottom: 28,
                    },
                    '.body': {
                        fontSize: 15,
                        lineHeight: '22px',
                        letterSpacing: '0.1em',
                    },
                }
            },
        },
    }),

    inTheNews: css({
        paddingTop: 60,
        paddingBottom: 60,
        backgroundColor: colors.WHITE,

        '.logo-wrapper': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 48,
            [mobileOnly]: {
                marginTop: 39,
                flexDirection: 'column',
            },
            [tabletPortraitUp]: {
                flexDirection: 'column',
                marginTop: 30,
            },
            [tabletLandscapeUp]: {
                flexDirection: 'row',
            },
            'span': {
                cursor: 'pointer',
                opacity: 0.5,
                [mobileOnly]: {
                    display: 'none',
                },
                [tabletPortraitUp]: {
                    display: 'none',
                },
                [tabletLandscapeUp]: {
                    display: 'block',
                },
                '&.active': {
                    opacity: 1,
                    display: 'block',
                }
            },
            'div.dots': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'row',
                gap: 50,
                [mobileOnly]: {
                    gap: 21,
                },
                [tabletLandscapeUp]: {
                    display: 'none',
                },
                '.dot': {
                    height: 12,
                    width: 12,
                    borderRadius: 7,
                    backgroundColor: colors.NAVY,
                    display: 'block',
                    opacity: 1,
                    [mobileOnly]: {
                        height: 10,
                        width: 10,
                    },
                    [tabletPortraitUp]: {

                    },
                    '&.active': {
                        borderRadius: 12,
                        backgroundColor: colors.RED,
                        [mobileOnly]: {
                            height: 18,
                            width: 18,
                        },
                        [tabletPortraitUp]: {
                            height: 32,
                            width: 32,
                            borderRadius: '50%',
                        },
                        [tabletLandscapeUp]: {
                            height: 24,
                            width: 24,
                        }
                    }
                },
            },
            'img': {
                maxWidth: '195px !important',
                width: '100%',
                height: 'auto',
                display: 'block',
                alignSelf: 'center',
                [mobileOnly]: {
                    maxWidth: '195px !important',
                }
            }
        },
        '.sub-heading': {
            color: colors.NAVY,
            fontWeight: 700,
            fontSize: 25,
            lineHeight: '25px',
            maxWidth: 956,
            width: '100%',
            margin: '0 auto',

            [mobileOnly]: {
                fontSize: 15,
                lineHeight: '21.75px',
                minHeight: 65,
            },

            '.e-quote': {
                fontSize: 'inherit',
            },

            'a.e-logo-link': {
                display: 'none',
                textDecoration: 'none',
                '&.active': {
                    display: 'block',
                },
            },
        },
    }),

    ageBundles: css({
        overflow: 'hidden',
        backgroundColor: colors.TEAL_EXTRA_LIGHT,
        paddingTop: 80,
        [mobileOnly]: {
            paddingBottom: 80,
        },
        [tabletPortraitUp]: {
            paddingBottom: 80,
        },
        [tabletLandscapeUp]: {
            paddingBottom: 70,
        },
        'h2': {
            margin: '0 auto',
            fontSize: 32,

            [mobileOnly]: {
                fontSize: 22
            },
        },
        '.take-the-quiz-button': {
            position: 'relative',
            display: 'inline-flex',
            alignSelf: 'center',
            img: {
                position: 'absolute',
                width: 35,
                height: 49,
                top: -12,
                right: -32,
            }
        },
        '.product-bundles': {
            display: 'flex',
            gap: 60,

            [mobileOnly]: {
                justifyContent: 'center',
            },
            [tabletPortraitUp]: {
                justifyContent: 'center',
                gap: 30,
                minHeight: 600,
            },
            [tabletLandscapeUp]: {
                justifyContent: 'center',
                gap: 40,
            },
            [desktopUp]: {
                flexWrap: 'initial',
                justifyContent: 'initial',
            }
        },
        '.bundle': {
            maxWidth: 300,
            width: '100%',
            position: 'relative',
            [mobileOnly]: {
                maxWidth: 'calc(100% - 60px)',
            },
            [tabletPortraitUp]: {
            },
            [tabletLandscapeUp]: {

            },
            '.bundle-information': {
                color: colors.PINK_LIGHT,
                textAlign: 'center',
                position: 'relative',
                [mobileOnly]: {
                    borderRadius: 30,
                    padding: '34px 30px 50px 30px',
                },
                [smallMobile]: {
                    borderRadius: 30,
                    padding: '34px 30px 50px 30px',
                },
                [tabletPortraitUp]: {
                    borderRadius: 30,
                    padding: '34px 30px 50px 30px',
                },
                [tabletLandscapeUp]: {
                    borderRadius: 50,
                    padding: '34px 30px 50px 30px',
                },
                '.bundle-header': {
                    marginBottom: 37,
                    '.period': {
                        fontSize: 46,
                        lineHeight: '58px',
                        textTransform: 'uppercase',
                        display: 'block',
                        whiteSpace: 'pre',
                    },
                    '.months': {
                        fontSize: 16,
                        lineHeight: '20px',
                        letterSpacing: '3px',
                        textTransform: 'uppercase',
                    },
                },
                '.bundle-description': {
                    fontSize: 16,
                    lineHeight: '28px',
                    marginBottom: 20,

                    [tabletLandscapeUp]: {
                        minHeight: 196,
                    },

                    [desktopUp]: {
                        minHeight: 196,
                    }
                },
                '.bundle-button': {
                    marginBottom: 70,
                    position: 'relative',
                    display: 'inline-flex',
                    '&:after': {
                        content: `""`,
                        position: 'absolute',
                        top: 25,
                        left: -17,
                        width: 18,
                        height: 24,
                        backgroundImage: `url(${img('homepage3/bundle-leaf-left.webp')})`,
                        backgroundSize: '100%',
                        opacity: 0,
                        transition: `opacity 0.3s ease-in`
                    },
                    '&:before': {
                        content: `""`,
                        position: 'absolute',
                        top: -31,
                        right: -10,
                        width: 26,
                        height: 52,
                        backgroundImage: `url(${img('homepage3/bundle-leaf-right.webp')})`,
                        backgroundSize: '100%',
                        opacity: 0,
                        transition: `opacity 0.3s ease-in`,
                    },
                    '&:hover': {
                        '&:before': {
                            opacity: 1,
                        },
                        '&:after': {
                            opacity: 1,
                        },
                    },
                    '> .button.red.v2': {
                        borderRadius: 27,
                    },
                    [mobileOnly]: {
                        marginBottom: 120,
                    },
                }
            },
            '.bundle-image': {
                position: 'relative',
                [mobileOnly]: {
                    marginRight: -30,
                    marginLeft: -30,
                    marginTop: -110,
                },
                [smallMobile]: {
                    marginRight: -30,
                    marginLeft: -30,
                    marginTop: -160,
                },
                [tabletPortraitUp]: {
                    marginRight: -70,
                    marginLeft: -70,
                    marginTop: -110,
                },
                [tabletLandscapeUp]: {
                    marginRight: -30,
                    marginLeft: -30,
                    marginTop: -100,
                },
                [desktopUp]: {
                    marginRight: -70,
                    marginLeft: -70,
                    marginTop: -110,
                },
                'img': {
                    maxWidth: '100%',
                    height: 'auto',
                    display: 'block',
                    margin: 'auto',
                    maxHeight: 420,
                }
            },
            '&.one': {
                '.bundle-information': {
                    backgroundColor: colors.HOMEPAGE_BUNDLE_ONE,
                },
                [tabletLandscapeUp]: {
                    display: 'block',
                }
            },
            '&.two': {
                '.bundle-information': {
                    backgroundColor: colors.HOMEPAGE_BUNDLE_TWO,
                },
                [tabletLandscapeUp]: {
                    display: 'block',
                }
            },
            '&.three': {
                '.bundle-information': {
                    backgroundColor: colors.HOMEPAGE_BUNDLE_THREE,
                },
                [tabletLandscapeUp]: {
                    display: 'block',
                }
            },
            '&.four': {
                '.bundle-information': {
                    backgroundColor: colors.HOMEPAGE_BUNDLE_FOUR,
                },
                [tabletLandscapeUp]: {
                    display: 'block',
                }
            },
        },
        '.slide-new-products-dots': {
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '0 auto',
            marginBottom: 61,
            [mobileOnly]: {
                maxWidth: 191,
                display: 'flex',
            },
            [tabletPortraitUp]: {
                display: 'flex',
                maxWidth: 218,
            },
            [tabletLandscapeUp]: {
                maxWidth: 90,
                display: 'none',
            },
            [desktopUp]: {
            },
            '.dot': {
                backgroundColor: colors.NAVY,
                borderRadius: 5,
                display: 'block',
                cursor: 'pointer',
                [mobileOnly]: {
                    width: 10,
                    height: 10,
                },
                [tabletPortraitUp]: {
                    width: 12,
                    height: 12,
                },
                [tabletLandscapeUp]: {
                    width: 6,
                    height: 6,
                },
                '&.active': {
                    borderRadius: 8,
                    backgroundColor: colors.RED,
                    cursor: 'none',
                    [mobileOnly]: {
                        width: 18,
                        height: 18,
                        borderRadius: 10,
                    },
                    [tabletPortraitUp]: {
                        width: 24,
                        height: 24,
                        borderRadius: 16,
                    },
                    [tabletLandscapeUp]: {
                        width: 12,
                        height: 12,
                    },
                }
            }
        }
    }),

    ChooseAdventure: css({
        padding: '5rem 0 10rem',
        backgroundColor: colors.TEAL_EXTRA_LIGHT,

        '.wrapper': {
            overflow: 'hidden'
        },

        '.header': {
            height: '130px',
            padding: '2rem',
            backgroundColor: colors.HOMEPAGE_BUNDLE_THREE,
            borderRadius: '1rem 1rem 0 0',
            color: colors.WHITE,
            fontSize: 32,
            lineHeight: '46px',
            fontWeight: 800,

            [mobileOnly]: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: 22,
                lineHeight: '30px'
            },
        },

        '.section-carousel': {
            backgroundColor: colors.MINT,
            borderRadius: '0 0 1rem 1rem',

            '.slick-slider': {
                '.column': {
                    display: 'flex !important',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                    margin: '0 auto',
                },

                '.slick-prev:before, .slick-next:before': {
                    color: colors.BLUE,
                },

                '.slick-prev, .slick-next': {
                    top: 'auto',
                    bottom: '3rem',
                    backgroundColor: colors.WHITE,
                    borderRadius: '50%',
                    height: '30px',
                    width: '30px',
                    fontWeight: 800
                },

                '.slick-prev': {
                    left: '50%',
                    transform: 'translate(-110%, 0)',
                    zIndex: 1
                },

                '.slick-next': {
                    left: '50%',
                    transform: 'translate(10%, 0)',
                },

                '.slick-list': {
                    overflow: 'visible'
                }
            },

            '.slider': {
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'end',

                '.column': {
                    flex: '1 1 50%'
                }
            },

            '.column': {
                maxWidth: '300px',
                position: 'relative',

                [mobileOnly]: {
                    padding: '10rem 2rem 9rem 2rem',
                },

                [tabletPortraitUp]: {
                    padding: '0 2rem 9rem 2rem',
                },

                [tabletLandscapeUp]: {
                    padding: '10rem 2rem 6rem 2rem'
                },

                '.bundle-image': {
                    position: 'relative',
                    [smallMobile]: {
                        marginRight: -30,
                        marginLeft: -30,
                        marginTop: -120,
                    },
                    [tabletLandscapeUp]: {
                        marginRight: -30,
                        marginLeft: -30,
                        marginTop: -150,
                    },
                    'img': {
                        maxWidth: '100%',
                        height: 'auto',
                        display: 'block',
                        margin: 'auto',
                        maxHeight: 420,
                    }
                },

                'h4': {
                    color: colors.NAVY,
                    fontWeight: 800,
                    fontSize: 18,
                    lineHeight: '26px',
                    textTransform: 'uppercase',
                    letterSpacing: '0.2em',
                },

                'p': {
                    fontWeight: 700,
                    fontSize: 15,
                    lineHeight: '22px',
                    letterSpacing: '0.02em',
                    marginBottom: '30px',
                    color: colors.BLUE
                },

                '&.bundle': {
                    'h4': {
                        maxWidth: '140px',
                        margin: '0 auto'
                    },

                    'img': {
                        transform: '  translate(5%, -10%)',

                        [mobileOnly]: {
                            transform: '  translate(3%, 0)',
                        }
                    }
                }
            },
        },
    }),

    UsVSThemDesktop: css({
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FFEAD4',
        minHeight: 700,
        position: 'relative',
        overflow: 'hidden',
        height: '100vh',

        '.pouch': {
            // maxWidth: '220px !important',
            color: colors.PINK,

            '&.hide': {
                display: 'none'
            },
            '&.abs': {
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: 'auto',
                // maxWidth: '220px !important',
                zIndex: 0
            },
            '&.left': {
                WebkitAnimation: 'slideLeft 1s forwards',
                WebkitAnimationDelay: '0s',
                animation: 'slideLeft 1s forwardsn',
                animationDelay: '0s',
            },
            '&.right': {
                WebkitAnimation: 'slideRight 1s forwards',
                WebkitAnimationDelay: '0s',
                animation: 'slideRight 1s forwards',
                animationDelay: '0s',
            }
        },
        '@-webkit-keyframes slideLeft': {
            '0%': {
                transform: 'translateX(0px)',
            },
            '100%': {
                transform: 'translateX(-550px)',
            }
        },
        '@keyframes slideLeft': {
            '0%': {
                transform: 'translateX(0px)',
            },
            '100%': {
                transform: 'translateX(-550px)',
            }
        },
        '@-webkit-keyframes slideRight': {
            '0%': {
                transform: 'translateX(0px)',
            },
            '100%': {
                transform: 'translateX(550px)',
            }
        },
        '@keyframes slideRight': {
            '0%': {
                transform: 'translateX(0px)',
            },
            '100%': {
                transform: 'translateX(550px)',
            }
        },

        '.left-slide1': {
            fontWeight: 800,
            fontSize: 32,
            lineHeight: '115%',
            color: colors.NAVY,
            marginTop: '-32px',
            marginRight: 10,
            width: 200,
            textAlign: 'left',

            span: {
                marginLeft: 32,
            }
        },
        '.right-slide1': {
            fontWeight: 800,
            marginLeft: 10,
            marginTop: 32,
            width: 200,
            textAlign: 'right',
        },

        '.left-slide2': {
            fontWeight: 800,
            fontSize: 32,
            lineHeight: '115%',
            color: colors.NAVY,
            marginRight: 20,
            width: 200,
            textAlign: 'right',
        },
        '.right-slide2': {
            marginLeft: 20,
            marginTop: 32,
            width: 200,
            textAlign: 'left',

            p: {
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: 15,
            },
            'a.button.red.v2': {
                display: 'block',
                maxWidth: 190,
                marginTop: 30,
                padding: 10,
            }
        },
        '.left-ilustration': {
            position: 'absolute',
            left: '5%',
            bottom: '5%',
            maxWidth: '350px !important'
        },
        '.right-ilustration': {
            position: 'absolute',
            right: '5%',
            bottom: '5%',
            maxWidth: '350px !important'
        },

        [desktopUp]: {
            '.pouch': {
                maxWidth: '100% !important',

                '&.abs': {
                    maxWidth: '280px !important',
                },
            },
            '.left-slide1': {
                fontSize: 44,
                marginTop: '-44px',
                width: 300,

                span: {
                    marginLeft: 44,
                }
            },
            '.right-slide1': {
                fontSize: 44,
                marginTop: 44,
                width: 270,
            },
            '.left-slide2': {
                fontSize: 44,
                marginTop: '-300px',
                marginRight: 40,
                width: 300,
            },
            '.right-slide2': {
                marginTop: '-180px',
                marginLeft: 40,
                width: 270,
            },
            '.left-ilustration': {
                position: 'absolute',
                maxWidth: '100% !important'
            },
            '.right-ilustration': {
                position: 'absolute',
                maxWidth: '100% !important'
            },
        }

    }),
    UsVSThemMobile: css({
        minHeight: 860,
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        backgroundColor: '#FFEAD4',

        h4: {
            color: '#FC4D38',
            fontWeight: 800,
            fontSize: 14,
            textTransform: 'uppercase',
            marginBottom: 10,
            textAlign: 'center',
        },

        h2: {
            color: '#15206B',
            fontWeight: 800,
            fontSize: 26,
            textTransform: 'lowercase',
            marginBottom: 30,
            textAlign: 'center',
        },

        '.slide': {
            width: '100%',
            padding: '40px 15px',
        },

        p: {
            fontWeight: 700,
            fontSize: 15,
            letterSpacing: '0.02em',
            textAlign: 'center',
            marginBottom: 30,
        },

        '.fp-overflow': {
            height: '100%',
        },
        '.pouch-animation': {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'nowrap',
            flexDirection: 'row',
            position: 'relative',
            justifyContent: 'center',

            '.first': {
                position: 'relative',
                marginBottom: 'auto',
                marginTop: '-30px',
            },
            '.last': {
                position: 'relative',
                marginTop: 'auto',
                marginBottom: '-50px',
                maxWidth: 140,
                width: '100%'
            },
            '.ilustrations': {
                display: 'flex',
                alignItems: 'flex-end',
                transition: 'margin 1000ms ease-in-out, box-shadow 1000ms ease-in-out',

                '&.slide-down': {
                    marginBottom: '-280px'
                }
            }
        }
    }),
    UsVSThemTable: css({
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        backgroundColor: '#FFEAD4',

        '.content': {
            marginLeft: 100,
            marginRight: 100,
        },
        'a.button.red.v2': {
            display: 'block',
            maxWidth: 190,
            marginTop: 30,
            padding: 10,
            marginLeft: 'auto',
            marginRight: 'auto',
        },

        '.table-wrapper': {
            borderRadius: 20,
            overflow: 'hidden',
            '.table': {
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                alignContent: 'flex-start',
                alignItems: 'center',
                justifyContent: 'center',

                '.column': {

                    '.tr': {
                        height: 55,
                        width: 70,
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        alignContent: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        fontSize: 13,

                        '&.white': {
                            backgroundColor: 'white'
                        },
                        '&.gray': {
                            backgroundColor: '#F2F2F2'
                        }
                    },

                    '&.first': {
                        '.tr': {
                            width: 220,
                            justifyContent: 'flex-start',
                            paddingLeft: 20,
                            lineHeight: 1.6,
                        },
                        '.t-header': {
                            width: '100%',

                            img: {
                                width: '100%',
                                height: '100%',
                            }
                        }
                    },

                    '.t-header': {
                        backgroundColor: colors.NAVY,
                        width: 70,
                        height: 70,
                        display: 'flex',
                        flexWrap: 'nowrap',
                        justifyContent: 'center',
                        alignItems: 'center',

                        img: {
                            position: 'relative',
                            maxHeight: 40,
                        }
                    }
                }
            },
            '.t-footer': {
                display: 'flex',
                padding: 20,
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'center',
                backgroundColor: colors.NAVY,
                color: 'white !important',
            },

            '.learn-more': {
                color: `${colors.WHITE} !important`,
                fontStyle: 'normal !important',
                fontWeight: '700 !important',
                fontSize: '13px !important',
                lineHeight: '16px !important',
                letterSpacing: '0.2em !important',

                '&:hover': {
                    color: `${colors.WHITE} !important`,
                }
            }
        },
    }),

    ParentingIsAnArt: css({
        padding: '10rem 0',
        backgroundColor: colors.PINK_EXTRA_LIGHT,
        position: 'relative',

        '.separator': {
            maxWidth: '100%',
            width: '100%',
            marginTop: '-10px',
            position: 'absolute',
            bottom: 0,
        },

        '.leafs': {
            position: 'absolute',
            bottom: 0,

            '&.left': {
                left: 0,

                [mobileOnly]: {
                    display: 'none'
                }
            },

            '&.right': {
                right: 0
            },
        },

        '.wrapper': {
            margin: '0 auto',
            maxWidth: '950px',

            '.loader': {
                width: '100%',
            },

            '.intro-wrapper': {
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '40px',

                '.intro': {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',

                    'img': {
                        width: '100%',
                        maxWidth: '460px'
                    },

                    '.content': {
                        padding: '24px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'start',
                        textAlign: 'left',
                        color: colors.NAVY,

                        'h4': {
                            fontWeight: 800,
                            fontSize: 32,
                            lineHeight: '46px',
                            textTransform: 'lowercase',
                            letterSpacing: '0.02em',
                            marginBottom: '30px'
                        },

                        'p': {
                            fontWeight: 700,
                            fontSize: 15,
                            lineHeight: '22px',
                            letterSpacing: '0.02em',
                        },
                    },

                    '@media (max-width: 900px)': {
                        alignItems: 'center',
                        maxWidth: '100%',

                        '.content': {
                            alignItems: 'center',

                            'h4': {
                                fontSize: 22,
                                lineHeight: '30px',
                                marginBottom: '20px'
                            },

                            'p': {
                                textAlign: 'center'
                            }
                        }
                    },

                    [tabletLandscapeUp]: {
                        flexDirection: 'row',
                    },
                }
            },

            '.section-carousel': {
                '.slick-disabled + .slick-list:before': {
                    display: 'none',
                },

                '.slick-list:before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    height: '50px',
                    width: '18%',
                    backgroundColor: colors.WHITE,
                    background: 'linear-gradient(90deg, rgba(255,236,240,1) 0%, rgba(255,236,240,0.8) 25%, rgba(255,236,240,0) 50%, rgba(255,236,240,0) 75%, rgba(255,255,255,0) 100%)',
                    zIndex: 200,
                    left: 0,
                    transition: 'opacity 1.5s ease-in-out 1s',
                },

                '.slick-list-after:after': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    height: '50px',
                    width: '18%',
                    backgroundColor: colors.WHITE,
                    background: 'linear-gradient(270deg, rgba(255,236,240,1) 0%, rgba(255,236,240,0.65) 20%, rgba(255,236,240,0) 50%)',
                    zIndex: 200,
                    right: 0,
                    transition: 'opacity 1.5s ease-in-out 1s',
                },

                '.slick-slider': {
                    display: 'flex',

                    '.slick-track': {
                        marginRight: '-1.2rem',

                        '.slick-slide': {
                            '> div': {
                                padding: '0 1.2rem'
                            },
                        },
                    },

                    '.category': {
                        display: 'flex !important',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: colors.WHITE,
                        height: '49px',
                        textDecoration: 'none',
                        border: `solid 2px #FFECF0`,
                        borderRadius: 17,
                        backgroundColor: colors.PINK_DARK,

                        '&.active': {
                            borderColor: colors.PINK_DARK,
                        },

                        '.icon': {
                            backgroundColor: colors.PINK_LIGHT,
                            borderRadius: '15px 0 0 15px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minWidth: '50px',
                            height: '100%',
                        },

                        '.name': {
                            padding: '1.3rem 0',
                            fontWeight: 700,
                            fontSize: 15,
                            lineHeight: '19px',
                            textTransform: 'uppercase',
                            letterSpacing: '0.2em',
                            backgroundColor: colors.PINK_DARK,
                            borderRadius: '0 15px 15px 0',
                            width: '80%'
                        }
                    },

                    '.slick-prev:before, .slick-next:before': {
                        color: colors.WHITE,
                    },

                    '.slick-prev, .slick-next': {
                        backgroundColor: colors.PINK,
                        borderRadius: '50%',
                        height: '30px',
                        width: '30px',
                        fontWeight: 800,
                        zIndex: 1,

                        '&.slick-disabled': {
                            display: 'none !important'
                        }
                    },

                    '.slick-prev': {
                        left: 0,
                        '&:before': {
                            lineHeight: '0.3 !important',
                        }
                    },

                    '.slick-next': {
                        right: 0,
                        '&:before': {
                            lineHeight: '0.3 !important',
                        }
                    }
                },

                '.slick-initialized::before': {
                    width: '100px',
                    height: '50px',
                    maskImage: '-webkit-gradient(linear, center top, right top, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))'
                }
            },

            '.articles': {
                display: 'flex',
                justifyContent: 'space-between',
                margin: '40px -1.2rem',
                position: 'relative',
                zIndex: 1,

                '.article': {
                    flex: '1 1 50%',
                    margin: '0 1.2rem',
                    display: 'flex',
                    backgroundColor: colors.PINK_LIGHT,
                    borderRadius: '30px',
                    maxWidth: '460px',

                    '.image': {
                        minWidth: '200px',
                        overflow: 'hidden',
                        height: '200px',
                        borderRadius: '30px 0 0 30px',

                        'img': {
                            marginLeft: '-50%',
                            marginTop: '-50%'
                        }
                    },

                    '.content': {
                        padding: '3.5rem 2rem',
                        display: 'flex',
                        textAlign: 'left',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        minHeight: '100%',
                        alignItems: 'start',

                        '.title': {
                            fontWeight: 700,
                            fontSize: 18,
                            lineHeight: '24px',
                            letterSpacing: '0.02em',
                            color: colors.NAVY,
                        },

                        '.explore': {
                            fontWeight: 700,
                            fontSize: 13,
                            lineHeight: '16px',
                            letterSpacing: '0.2em',

                            [mobileOnly]: {
                                marginTop: '2rem'
                            }
                        }
                    },

                    '@media (max-width: 1000px)': {
                        flexDirection: 'column',
                        maxWidth: '320px',
                        margin: '1.2rem 0',

                        '.content': {
                            padding: '2rem',
                            alignItems: 'center',
                            textAlign: 'center',
                            minHeight: '150px',
                        },

                        '.image': {
                            overflow: 'visible',
                            height: 'auto'
                        },

                        'img': {
                            marginLeft: '0 !important',
                            marginTop: '0 !important',
                            maxWidth: '100%',
                            borderRadius: '30px 30px 0 0 !important',
                        },
                    }
                },

                '@media (max-width: 1110px)': {
                    justifyContent: 'space-around',
                },

                '@media (max-width: 900px)': {
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }
            },

            '.topic': {
                marginTop: '30px',
                position: 'relative',
                zIndex: 1,

                '.filter': {
                    '&__control': {
                        border: 'none',
                        borderRadius: '100px',
                        backgroundColor: colors.PINK_DARK,

                        '&--is-focused': {
                            borderColor: 'none',
                            boxShadow: 'none'
                        }
                    },

                    '&__placeholder, &__single-value': {
                        color: colors.WHITE,
                        textTransform: 'uppercase',
                        fontWeight: 700,
                        fontSize: 13,
                        lineHeight: '16px',
                        letterSpacing: '0.2em',
                    },

                    '&__indicator-separator': {
                        display: 'none'
                    },

                    '&__indicator': {
                        color: colors.WHITE
                    },

                    '&__menu': {
                        margin: '1rem auto'
                    },

                    '&__option': {
                        justifyContent: 'center',

                        '&--is-focused': {
                            backgroundColor: colors.PINK_LIGHT
                        },

                        '&--is-selected': {
                            backgroundColor: colors.PINK_DARK,
                        }
                    }
                }
            }
        }
    }),

    Reviews: css({
        backgroundColor: colors.HOMEPAGE_REVIEWS_LIGHT_BLUE,
        position: 'relative',
        padding: '15rem 0',
        overflow: 'hidden',

        '@keyframes scroll': {
            '0%': {
                transform: 'translateX(0)',
            },
            '100%': {
                transform: 'translateX(calc(-390px * 10))'
            }
        },

        '.separator': {
            maxWidth: '100%',
            width: '100%',
            zIndex: 2,
            position: 'absolute',
            top: 0,
        },

        '.wrapper': {
            '.header': {
                marginBottom: '3rem',
                textAlign: 'center',
                fontWeight: 800,
                fontSize: 32,
                lineHeight: '46px',
                letterSpacing: '0.02em',
            },

            '.slider': {
                margin: 'auto',
                overflow: 'hidden',
                position: 'relative',

                '.slide-track': {
                    animation: 'scroll 120s linear infinite',
                    display: 'flex',
                    width: 'calc(-390px * 10)',

                    '&:hover': {
                        animationPlayState: 'paused',
                    },

                    '.card': {
                        maxWidth: '350px',
                        minWidth: '350px',
                        display: 'flex !important',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        color: colors.NAVY,
                        borderRadius: '30px',
                        backgroundColor: colors.WHITE,
                        height: '350px',
                        overflow: 'hidden',
                        margin: '0px 20px',

                        picture: {
                            maxWidth: '100%',
                            maxHeight: '100%',
                        },

                        '&.review': {
                            padding: '3.5rem 2.5rem'
                        },

                        '.stars': {
                            display: 'flex',

                            'img': {
                                width: '2rem',
                                height: '2rem'
                            }
                        },

                        'h4': {
                            marginTop: '1rem',
                            fontWeight: 800,
                            fontSize: 16,
                            lineHeight: '24px',
                            letterSpacing: '0.2em',
                        },

                        'p': {
                            margin: '2rem 0 1rem',
                            fontWeight: 700,
                            fontSize: 15,
                            lineHeight: '22px',
                            letterSpacing: '0.02em',
                        },

                        '.author': {
                            fontWeight: 700,
                            fontSize: 13,
                            lineHeight: '18px',
                            letterSpacing: '0.01em',
                            textTransform: 'uppercase'
                        },

                        [mobileOnly]: {
                            // width: '300px'
                        },
                    },
                },
            },

            '.view-all': {
                display: 'flex',
                justifyContent: 'center',
                marginTop: '3rem',
                textAlign: 'center',

                '.explore': {
                    position: 'relative',
                    overflow: 'hidden',
                    fontWeight: 700,
                    fontSize: 13,
                    lineHeight: '16px',
                    letterSpacing: '0.2em',
                    textTransform: 'uppercase',
                    textDecoration: 'none',
                    transition: 'all 0.3s ease-in-out 0s',
                    verticalAlign: 'middle',
                    paddingBottom: '6px',
                    zIndex: 10,

                    '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        width: '100%',
                        height: '2px',
                        animationDuration: '0.8s',
                        animationTimingFunction: 'cubic-bezier(0.58, 0.3, 0.005, 1)',
                        animationFillMode: 'forwards',
                        transition: 'inherit',
                        backgroundColor: '#FC4D38',
                    },

                    '&:hover': {
                        textDecoration: 'none',

                        '&:before': {
                            animationName: 'bottomFillAnim'
                        }
                    }
                }
            },

            '.leafs': {
                position: 'absolute',
                bottom: 0,

                '&.left': {
                    left: 0
                },

                '&.right': {
                    right: 0,

                    '@media (max-width: 767px)': {
                        display: 'none'
                    }
                },
            },
        }
    })
};
