import { Link, useLocation } from 'react-router-dom';

import { packAndTrack } from '@v2/services/analytics.service';

import styles from '@v2/components/common/presentational/footer/footer.styles';
import v2Styles from '@styles/wrapper';
import { imgV2 as img } from '@styles/utils';
import { routeInList } from '@v2/utils/routeInList';
// import { useOnAccesibe } from '@app/store/hooks/accessibe';

const fullScreenRoutes = [
    '/checkout',
    '/cart',
    '/account-settings/cancellation',
    '/subscription',
    '/shipping-billing',
    '/account-settings',
    '/orders',
    '/edit',
    '/swap',
    '/buy-again',
    '/products',
    '/survey',
    '/cancellation',
    '/referral',
    '/gift',
    '/child',
    '/order-confirmation',
    '/landing/nourishment-for-every-stage-of-growth',
];

const social_link_data = [
    {
        img_name: img('footer/facebook.svg'),
        url: 'https://www.facebook.com/EatCerebelly-105858454130686/',
    },
    {
        img_name: img('footer/twitter.svg'),
        url: 'https://twitter.com/eatcerebelly',
    },
    {
        img_name: img('footer/instagram.svg'),
        url: 'https://www.instagram.com/eatcerebelly/',
    }
];

const links = [
    {
        title: 'why cerebelly',
        links: [
            {
                url: '/about-cerebelly/',
                text: 'our story',
            },
            {
                url: '/baby-food-delivered/',
                text: 'how it works',
            },
            {
                url: '/science/',
                text: 'science',
            },
            {
                url: '/parenting-is-an-art',
                text: 'blog',
            },
            {
                url: 'https://cerebelly.ca',
                text: 'cerebelly in Canada',
            },
            {
                url: 'https://cerebelly.com/?modal=international',
                text: 'cerebelly internationally',
            },
        ]
    },
    {
        title: 'learn',
        links: [
            {
                url: 'https://help.cerebelly.com',
                text: 'faqs',
            },
            {
                url: 'https://help.cerebelly.com',
                text: 'contact us',
            },
            {
                url: '/heavy-metals',
                text: 'safety & standards',
            },
            {
                url: 'https://www.terracycle.com/en-US/brigades/cerebelly',
                text: 'recycling program',
            },
            {
                url: '/where-to-buy/',
                text: 'where to buy',
            },
            {
                url: '/accessibility-statement/',
                text: 'accessibility statement',
            },
            {
                url: 'https://help.cerebelly.com/en/articles/9134487-i-m-an-influencer-that-would-like-to-partner-with-cerebelly-how-do-i-apply-to-cerebelly-s-affiliate-program',
                text: 'join our affiliate program'
            }
        ]
    },
    {
        title: 'shop',
        links: [
            {
                url: '/shop/',
                text: 'shop all',
            },
            {
                url: '/shop/#quiz',
                text: 'take the quiz',
            },
            {
                url: '/shop/#builder',
                text: 'build a box',
            },
            {
                url: '/gift',
                text: 'gift',
            },
            {
                url: '/rewards/',
                text: 'rewards',
            },
        ]
    }
];

const eventAction = (name: string) => {
    packAndTrack(
        `${name} Clicked`,
        [{ 'view': 'Footer', 'section': 'Footer' }]);
}

function FooterMainLinks() {
    return (
        <div className="nav">
            {links.map((column, index) =>
                <div className="column" key={index}>
                    <h3>{column.title}</h3>
                    {column.links.map((row, index) =>
                        <div key={index}>
                            {row.url.startsWith('http')
                                ? <a
                                    key={index}
                                    className="e-link"
                                    target="_blank"
                                    rel="noreferrer"
                                    onClick={() => eventAction(row.text)}
                                    href={row.url} >{row.text}</a>
                                : <Link
                                    key={index}
                                    className="e-link"
                                    to={row.url}
                                    onClick={() => eventAction(row.text)}
                                >{row.text}</Link>
                            }
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

function SocialLinks() {
    return (
        <div className="social">
            {social_link_data.map((link_data, index) => {
                return (
                    <a className="link"
                        href={link_data.url}
                        target="_blank"
                        rel="noreferrer"
                        key={index}>
                        <img loading="lazy" src={link_data.img_name} alt={`${link_data.img_name} Logo`} />
                    </a>
                );
            })
            }
        </div>
    );
}

function Terms() {

    return (
        <div className="footer-bottom">
            <span
                role="button"
                tabIndex={0}
                data-acsb-custom-trigger="true"
                onClick={() => eventAction('Accessibility')}>
                Accessibility
            </span>
            <Link
                to={'/privacy'}
                onClick={() => eventAction('Privacy Policy')}>
                Privacy Policy
            </Link>
            <Link
                to={'/terms'}
                onClick={() => eventAction('Terms of Use')}>
                Terms of Use
            </Link>
            <Link
                to={'/messaging-privacy'}
                onClick={() => eventAction('Messaging Privacy Policy')}>
                Messaging Privacy Policy
            </Link>
        </div>
    );
}

function FooterContent() {
    return (
        <>
            <div className="footer-main">
                <div className="left">
                    <FooterMainLinks />
                </div>
                <div className="right">
                    <SocialLinks/>
                    <div className="mobile">
                        <Terms/>
                    </div>
                    <Link className="logo-link" to='/'>
                        <img loading="lazy" src={img("/footer/cb-footer-logo.svg")} alt="Cereblly Logo" />
                    </Link>
                    <div className="copy">&copy; {new Date().getFullYear()} Cerebelly. All Rights Reserved.</div>
                </div>
            </div>
            <div className="desktop">
                <Terms/>
            </div>
        </>
    );
}

export function FooterSection() {
    return (
        <div className={`${styles} ${v2Styles.v2Global}`}>
            <footer>
                <FooterContent/>
            </footer>
        </div>
    );
}

export default function Footer() {
    const location = useLocation();

    return (
        <>
            {
                !routeInList(fullScreenRoutes, location.pathname) &&
                    <FooterSection />
            }
        </>
    );
}